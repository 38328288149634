@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif !important;
  background-color: #0D2638;
  overflow-x: hidden !important;

}

html {
  max-width: 100vw !important;
  overflow-x: hidden !important;
}


.navbar-nav a {
  position: relative;
  display: inline-block;
  color: white !important;
  text-decoration: none;
}

.navbar-nav a::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.scrolled {
  background-color: #0D2638 !important;
  transition: background-color 350ms ease-in-out;
}

#basic-navbar-nav>div>a.nav-item.mx-auto::after {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.navbar-nav a,
.navbar-nav a::after {
  transition: all 0.4s linear;
}

.navbar-nav a:hover::after {
  left: 15%;
  width: 70%;
}

.navbar-nav a-last {
  color: white !important;
}

.dark-color {
  color: #0D2638;
}

.navbar-brand img {
  width: 70%;
}

.navbar-nav a button {
  color: white !important;
  font-weight: bold;
  border-color: #1E87DF !important;
}

.navbar-nav a button:hover {
  background-color: #1E87DF !important;
  border-color: #1E87DF !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus,
button:focus {
  box-shadow: none !important;
}

.fridge-circle {
  position: relative;
  height: 400px;
  width: 400px;
  /* background: rgb(34, 193, 195);
  background: radial-gradient(circle, rgba(34, 193, 195, 0.5) 0%, rgba(26, 199, 255, 0.5) 22%, rgba(59, 115, 122, 0.5) 84%, rgba(13, 10, 44, 0.5) 100%); */
}

.map {
  border-radius: 32px !important;
}

.fridge-circle img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 120%;
  /* width: 100%; */
}

.intro-text-color {
  background: #1E87DF;
  background: linear-gradient(to right, #1E87DF 0%, #C0E2FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold !important;

}

.order-button {
  background-color: #1E87DF;
  border: none;

}

.extra-margin {
  margin-top: 9pc !important;
}

.intro-desc {
  font-size: 140%;
}

.grey-color {
  color: #8F8F8F;
}

.bold-text {
  font-weight: bold !important;
}

.increase-headline-size {
  font-size: 230%;
}

.hr-gradient {
  height: 2px !important;
  color: transparent;
  background-image: linear-gradient(to right, rgba(34, 76, 116, 0.2), rgba(34, 76, 116, 1), rgba(34, 76, 116, 0.2)) !important;
}

.hr-gradient-light {
  border: none;
  height: 4px !important;
  color: linear-gradient(to right, rgba(26, 199, 255, 0.2), rgba(26, 199, 255, 1), rgba(26, 199, 255, 0.2)) !important;
  background-image: linear-gradient(to right, rgba(26, 199, 255, 0.2), rgba(26, 199, 255, 1), rgba(26, 199, 255, 0.2)) !important;
}

.card {
  border-radius: 40px !important;
  height: 300px;
  background-image: linear-gradient(45deg,
      hsl(194deg 75% 51%) 0%,
      hsl(199deg 71% 45%) 25%,
      hsl(204deg 67% 39%) 50%,
      hsl(209deg 63% 33%) 75%,
      hsl(214deg 59% 27%) 100%);
}

.card-number {
  position: absolute;
  top: 0;
  left: 10%;
  transform: translateX(-70%);
  transform: translateY(-50%);
}

.card-img {
  position: absolute;
  top: 6%;
  left: 5%;
  transform: translateX(-50%);
  transform: translateY(-50%);
}

.light-background {
  background-color: #D2F4FF;
}

.icon-color {
  color: #1E87DF;
}

.snow-cap-transform {
  position: absolute;
  transform: translateX(-50%);
  transform: translateY(-50%);
}

.custom-form {
  border-radius: 32px !important;
}

.custom-button {
  border-radius: 20px !important;
}

.MuiOutlinedInput-root {
  border-radius: 20px !important;
}

.hide-overflow {
  overflow-x: hidden !important;
}

#secondSection {
  margin-top: -60pt;
}

.advantages-icon {
  width: 40px;
  height: 40px;

}

.cursor-pointer {
  cursor: pointer;
}

/* contact form animation */

#send-button {
  position: relative;
  padding: 12px 35px;
  display: block;
  width: 200px;
  overflow: hidden;
  border-radius: 35px;
  background-color: transparent !important;
  border: none;
}

#send-button span {
  font-weight: bold;
  position: relative;
  color: #fff;
  font-size: large;
  z-index: 1;
}

#send-button .liquid {
  position: absolute;
  top: -80px;
  left: 0;
  width: 200px;
  height: 200px;
  background: #1E87DF !important;
  /* box-shadow: inset 0 0 50px rgba(0, 0, 0, .5); */
  transition: .5s;

}

#send-button .liquid::after,
#send-button .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -75%);
}

#send-button .liquid::before {
  border-radius: 45%;
  background: rgba(13, 38, 56, 1);
  animation: animate 5s linear infinite;
}

#send-button .liquid::after {
  border-radius: 40%;
  background: rgba(13, 38, 56, 0.5);
  animation: animate 10s linear infinite;
}

#send-button:hover .liquid {
  top: -120px;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}


@media (max-width:481px) {
  #secondSection {
    margin-top: 10pt;
  }

  .extra-bottom-margin {
    margin-bottom: 0 !important;
  }

  .extra-margin {
    margin-top: 4pc !important;
  }

  .fridge-circle {
    margin-top: 90pt;
    height: 300px;
    width: 300px;
  }

  #root>div>div>div:nth-child(2) {
    margin: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

@media (max-width:992px) {
  .extra-bottom-margin {
    margin-bottom: 140px;
  }
}

@media (min-width:1200px) {
  .extra-margin {
    margin-top: 13pc !important;
  }
}